import React from 'react'
import { graphql } from 'gatsby'

import SEO from '../components/seo'
import Tile from '../components/tile'

const BlogPage = ({ data }) => (
  <div className="grid gap-8 grid-cols-1 md:grid-cols-2 xl:grid-cols-3">
    <SEO title="Blog" />
    {data.posts.edges.map(({ node }) => {
      return (
        <Tile
          key={node.id}
          to={node.slug}
          title={node.title}
          fluid={node.coverImage.localFile.childImageSharp.fluid}
          metaData={[node.topic, new Date(node.publishedOn).toDateString()]}
        />
      )
    })}
  </div>
)

export const query = graphql`
  query BlogPageQuery {
    posts: allContentfulBlogPost (sort: {fields: publishedOn, order: DESC}) {
      edges {
        node {
          id
          title
          slug
          topic
          publishedOn
          coverImage {
            id
            title
            localFile {
              childImageSharp {
                fluid(maxWidth: 800, maxHeight: 500) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

export default BlogPage
